<template>
  <section class="login-page">
    <div class="main-box">
      <div class="form-box">
        <div class="logo">管理系统</div>
        <div class="error-container">
          <a-alert type="error" v-if="errMsg" :message="errMsg" showIcon />
        </div>

        <div class="form-row">
          <div class="icon icon-worknumber"></div>
          <div class="form-control">
            <a-input v-model="username" class="login-form-input" placeholder="请输入账号" @change="resetError">
              <a-icon slot="prefix" type="user" />
            </a-input>
          </div>
        </div>

        <div class="form-row">
          <div class="icon icon-password"></div>
          <div class="form-control">
            <a-input
              v-model="password"
              type="password"
              @change="resetError"
              class="login-form-input"
              placeholder="请输入密码"
              @pressEnter="handleSubmit"
            >
              <a-icon slot="prefix" type="lock" />
            </a-input>
          </div>
        </div>
        <div class="flex justify-end">
          <a @click="oaLogin">oa系统登陆</a>
        </div>
        <a-button class="login-btn" block round @click="handleSubmit" type="primary">登录</a-button>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      errMsg: '',
    }
  },
  mounted() {},
  methods: {
    resetError() {
      this.errMsg = ''
    },
    checkInput() {
      const { username, password } = this

      if (!username) {
        this.errMsg = '请输入账号'
        return false
      }

      if (!password) {
        this.errMsg = '请输入密码'
        return false
      }

      return true
    },
    async handleSubmit(e) {
      e.preventDefault()
      const { username, password } = this

      if (!this.checkInput()) {
        return false
      }

      const loginParams = {
        username,
        password,
      }
      this.$store
        .dispatch('Login', loginParams)
        .then((res) => this.loginSuccess(res))
        .catch((err) => this.requestFailed(err))
    },
    loginSuccess(res) {
      const toPath = decodeURIComponent(this.$route.query.redirect || '/')
      this.$router.replace(toPath).catch((e) => {
        // console.log(e)
      })
      setTimeout(() => {
        this.$notification.success({
          message: '登陆成功',
          description: `欢迎回来`,
        })
      })
    },
    requestFailed(err) {
      // console.log(err)
      if (err.code === 'E_REQUEST_CANCEL' || err.code === 'E_HAS_RESOLVED') {
        return
      }
      this.errMsg = err.message
    },
    oaLogin() {
      this.$router.push({
        path: '/oaSso/loginStart',
      })
    },
  },
}
</script>
<style lang="less" scoped>
.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
  .main-box {
    background-size: 100%;
    height: 814px;
    position: absolute;
    width: 1086px;
    .form-box {
      background: #fff;
      border-radius: 4px;
      box-shadow: rgba(39, 54, 78, 0.12) 1px 0px 6px 0px;
      border: 2px solid #ccc;
      min-height: 440px;
      left: 635px;
      position: absolute;
      top: 196px;
      width: 362px;
      padding: 40px 75px;
      .logo {
        text-align: center;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.85);
        font-family: Myriad Pro, Helvetica Neue, Arial, Helvetica, sans-serif;
        font-weight: 600;
        position: relative;
        top: 2px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
      .form-row {
        width: 100%;
        margin-top: 15px;
        display: flex;
        align-items: center;
        height: 40px;
        .form-control {
          width: 0;
          flex: 1;
        }
      }
      .error-container {
        min-height: 40px;
      }
      .login-btn {
        margin-top: 40px;
      }
    }
  }
}
</style>
